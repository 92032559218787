<template>
    <div>
        <div class="d-flex justify-content-between mt-4 mb-2">
            <h3>Kunder</h3>
            <b-button v-if="isShowingAvailableCustomersToAdd"
                      variant="primary"
                      class="float-right"
                      :disabled="isSubmitting"
                      @click="addCustomerToUser()">
                Lägg till
            </b-button>
        </div>
        <template v-if="!isShowingAvailableCustomersToAdd">
            <b-form-group id="form-group-select"
                          label="Välj kund">
                <b-form-select v-model="selectedCustomer"
                               :disabled="isSubmitting"
                               :options="customersWithoutPermissions"
                               value-field="customerId"
                               text-field="customerName" />
            </b-form-group>

            <b-alert :show="validationByKey('Other').length > 0"
                     variant="danger">
                <ul class="text-danger mb-0">
                    <li v-for="message in validationByKey('Other')" :key="message">
                        {{ message }}
                    </li>
                </ul>
            </b-alert>

            <div class="d-flex justify-content-between mt-4">
                <b-button variant="warning"
                          :disabled="isSubmitting"
                          @click="isShowingAvailableCustomersToAdd = true">
                    Avbryt
                </b-button>
                <b-button variant="primary"
                          :disabled="isSubmitting"
                          @click="add(4)">
                    Lägg till
                </b-button>
            </div>
        </template>
        <b-table v-if="isShowingAvailableCustomersToAdd"
                 :items="alreadySelectedCustomers"
                 :fields="fields"
                 stacked="sm"
                 show-empty
                 small>
            <template #cell(writeAccess)="row">
                <b-form-checkbox @change="toggleWriteAccess($event, row.item.customerId)"
                                 v-model="row.item.hasWriteAccess" />
            </template>
            <template #cell(customerName)="row">
                <div class="overflow-hidden">
                    {{ row.item.customerName }}
                </div>
            </template>
            <template #cell(actions)="row">
                <font-awesome-icon icon="trash-alt"
                                   role="button"
                                   class="d-none d-sm-block text-red"
                                   size="lg"
                                   @click="confirm('remove', row.item.customerId)" />

                <b-button class="text-center d-block d-sm-none"
                          block
                          variant="danger"
                          :disabled="isSubmitting"
                          @click="confirm('remove', row.item.customerId)">
                    Ta bort
                </b-button>
            </template>
            <template #empty="">
                <h4 class="text-center">Inga resultat</h4>
            </template>
        </b-table>
    </div>
</template>

<script>
    import { get, post, remove } from '@/helpers/api';
    export default {
        props: {
            userId: String
        },
        data() {
            return {
                validationErrors: null,
                isSubmitting:false,
                selectedCustomer: null,
                isShowingAvailableCustomersToAdd: true,
                alreadySelectedCustomers: null,
                customersWithoutPermissions: [],
                fields: [
                    {
                        key: 'writeAccess',
                        label: 'Skriv',
                        sortable: false,
                        sortDirection: 'desc'
                    },
                    {
                        key: 'customerName',
                        label: 'Namn',
                        sortable: false,
                        sortDirection: 'desc'
                    },
                    { key: 'actions', label: '', tdClass: 'table-list--actions--wrapper' }
                ]
            };
        },
        async mounted() {
            await this.getAllCustomersByUserId();
        },
        methods: {
            validationByKey(code) {
                return this.validationErrors ? this.validationErrors[code] || [] : [];
            },
            toggleWriteAccess(shouldHaveWrite, selectedCustomerId) {
                this.selectedCustomer = selectedCustomerId;
                this.add(shouldHaveWrite ? 4 : 1);
            },
            async remove(customerId) {
                this.validationErrors = null;
                await remove(
                    'UserCustomerPermission',
                    `DeleteAll?customerId=${customerId}&userId=${this.userId}`
                )
                    .then((x) => {
                        this.getAllCustomersByUserId();
                        this.validationErrors = x.data.errors;
                    })
                    .catch(x => {
                        this.isSubmitting = false;
                        this.validationErrors = x.response.data.errors
                    });
            },
            async addCustomerToUser() {
                this.isShowingAvailableCustomersToAdd = false;
                await this.getAllCustomersWithoutAnyUserPermissions();
            },
            async add(accessMask) {
                this.validationErrors = null;
                this.isSubmitting = true;

                // Add user to customer.
                await post(
                    'UserCustomerPermission',
                    `Add`, { customerId: this.selectedCustomer, userId: this.userId, accessMask: accessMask }
                )
                    .then((x) => {
                        this.validationErrors = x.data.errors;
                        this.getAllCustomersByUserId();
                        this.isShowingAvailableCustomersToAdd = true;
                        this.selectedCustomer = null;
                        this.isSubmitting = false;
                    })
                    .catch(x => {
                        this.isSubmitting = false;
                        this.validationErrors = x.response.data.errors
                    });
            },
            async getAllCustomersByUserId() {
                this.validationErrors = null;

                // Get all users by customer.
                await get('UserCustomerPermission', `GetAllCustomersByUserId?id=${this.userId}`)
                    .then((x) => {
                        this.validationErrors = x.data.errors;
                        this.alreadySelectedCustomers = x.data;
                    })
                    .catch(x => {
                        this.isSubmitting = false;
                        this.validationErrors = x.response.data.errors
                    });
            },
            async getAllCustomersWithoutAnyUserPermissions() {
                this.validationErrors = null;

                // Get all users by customer.
                await get('UserCustomerPermission', `GetAllCustomersWithoutAnyUserPermissions?id=${this.userId}`)
                    .then((x) => {
                        this.validationErrors = x.data.errors;
                        this.customersWithoutPermissions = x.data;
                    })
                    .catch(x => {
                        this.isSubmitting = false;
                        this.validationErrors = x.response.data.errors
                    });
            },
            confirm(action, data) {
                this.$bvModal
                    .msgBoxConfirm('Vill du ta bort kunden från användaren?', {
                        title: 'Är du säker?',
                        size: 'sm',
                        //buttonSize: 'sm',
                        cancelVariant: 'primary',
                        cancelTitle: 'Nej',
                        okTitle: 'Ja',
                        okVariant: 'danger',
                        footerClass: 'p-2 justify-content-between',
                        hideHeaderClose: false,
                        centered: true
                    })
                    .then((confirmed) => {
                        if (confirmed) this[action](data);
                    })
                    .catch((err) => {
                        // An error occurred
                    });
            }
        }
    };
</script>
