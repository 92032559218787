<template>
    <div>
        <h1 v-if="!id">
            {{ form.id ? 'Ändra' : 'Skapa' }} {{selectedCustomer}}användare</h1>
        <hr />
        <div>
            <b-tabs v-model="activeTab" content-class="mt-3">
                <b-tab title="Grunddata">
                    <b-form @submit.prevent="onSubmit">
                        <div v-if="!isEditMode && isSuperAdmin">
                            <b-form-group id="input-group-organization"
                                          label="Organisation"
                                          label-for="input-organization">
                                <b-form-select id="input-organization"
                                               v-model="form.organizationId"
                                               :options="allOrganizations"
                                               :disabled="isSubmitting"
                                               value-field="id"
                                               text-field="name"
                                               aria-describedby="input-organization-help-block"
                                               required />
                                <b-form-text id="input-organization-help-block">
                                    <ul class="text-danger mb-0">
                                        <li v-for="message in validationByKey('OrganizationId')"
                                            :key="message">
                                            {{ message
            }}
            </li>
            </ul>
            </b-form-text>
            </b-form-group>
</div>

                        <b-form-group id="input-group-userName"
                                      label="Användarnamn"
                                      label-for="input-userName">
                            <b-form-input id="input-userName"
                                          v-model="form.userName"
                                          type="text"
                                          placeholder="Ange användarnamn"
                                          :disabled="isSubmitting"
                                          autocomplete="off"
                                          aria-describedby="input-userName-help-block"
                                          required />
                            <b-form-text id="input-userName-help-block">
                                <ul class="text-danger mb-0">
                                    <li v-for="message in validationByKey('UserName')"
                                        :key="message">
                                        {{ message }}
                                    </li>
                                </ul>
                            </b-form-text>
                        </b-form-group>

                        <b-form-group id="input-group-firstName"
                                      label="Förnamn"
                                      label-for="input-firstName">
                            <b-form-input id="input-firstName"
                                          v-model="form.firstName"
                                          type="text"
                                          placeholder="Ange förnamn"
                                          :disabled="isSubmitting"
                                          autocomplete="off"
                                          aria-describedby="input-firstName-help-block"
                                          required />
                            <b-form-text id="input-firstName-help-block">
                                <ul class="text-danger mb-0">
                                    <li v-for="message in validationByKey('FirstName')"
                                        :key="message">
                                        {{ message }}
                                    </li>
                                </ul>
                            </b-form-text>
                        </b-form-group>

                        <b-form-group id="input-group-lastName"
                                      label="Efternamn"
                                      label-for="input-lastName">
                            <b-form-input id="input-lastName"
                                          v-model="form.lastName"
                                          type="text"
                                          placeholder="Ange efternamn"
                                          :disabled="isSubmitting"
                                          autocomplete="off"
                                          aria-describedby="input-lastName-help-block"
                                          required />
                            <b-form-text id="input-lastName-help-block">
                                <ul class="text-danger mb-0">
                                    <li v-for="message in validationByKey('LastName')"
                                        :key="message">
                                        {{ message }}
                                    </li>
                                </ul>
                            </b-form-text>
                        </b-form-group>

                        <b-form-group id="input-group-email"
                                      label="Email"
                                      label-for="input-email">
                            <b-form-input id="input-email"
                                          v-model="form.email"
                                          type="email"
                                          placeholder="Ange email"
                                          :disabled="isSubmitting"
                                          autocomplete="off"
                                          aria-describedby="input-email-help-block"
                                          required />
                            <b-form-text id="input-email-help-block">
                                <ul class="text-danger mb-0">
                                    <li v-for="message in validationByKey('Email')"
                                        :key="message">
                                        {{ message }}
                                    </li>
                                </ul>
                            </b-form-text>
                        </b-form-group>

                        <div v-if="!isEditMode">
                            <b-form-group id="input-group-password"
                                          label="Lösenord"
                                          label-for="input-password">
                                <b-form-input id="input-text-password"
                                              v-model="form.password"
                                              type="password"
                                              placeholder="Ange lösenord"
                                              :disabled="isSubmitting"
                                              aria-describedby="input-password-help-block" />
                                <b-form-text id="input-password-help-block">
                                    <ul class="text-danger mb-0">
                                        <li v-for="message in validationByKey('Password')"
                                            :key="message">
                                            {{ message }}
                                        </li>
                                    </ul>
                                </b-form-text>
                            </b-form-group>

                            <b-form-group id="input-group-confirmPassword-password"
                                          label="Repetera lösenord"
                                          label-for="input-confirmPassword-password">
                                <b-form-input id="input-text-confirmPassword-password"
                                              v-model="form.confirmPassword"
                                              type="password"
                                              :disabled="isSubmitting"
                                              aria-describedby="input-confirmPassword-help-block" />
                                <b-form-text id="input-confirmPassword-help-block">
                                    <ul class="text-danger mb-0">
                                        <li v-for="message in validationByKey('ConfirmPassword')"
                                            :key="message">
                                            {{ message }}
                                        </li>
                                    </ul>
                                </b-form-text>
                            </b-form-group>
                        </div>

                        <b-form-group id="input-group-roles-password" label="Roller">
                            <b-form-checkbox-group id="input-roles-password"
                                                   v-model="form.roles"
                                                   :options="allRoles"
                                                   :disabled="isSubmitting"
                                                   value-field="name"
                                                   text-field="name" />
                        </b-form-group>

                        <b-alert :show="validationByKey('Other').length > 0"
                                 variant="danger">
                            <ul class="text-danger mb-0">
                                <li v-for="message in validationByKey('Other')" :key="message">
                                    {{ message }}
                                </li>
                            </ul>
                        </b-alert>

                        <b-alert :show="successMessage.length > 0"
                                 variant="success"
                                 class="mb-0 mt-4">
                            <h6 class="mb-0 text-center">
                                <font-awesome-icon icon="info-circle" /> {{ successMessage }}
                            </h6>
                        </b-alert>

                        <b-button v-b-tooltip.hover
                                  variant="primary"
                                  type="submit"
                                  class="float-right mt-4"
                                  :class="{ 'btn-block': id }"
                                  :disabled="isSubmitting"
                                  :title="isSubmitting ? 'Sparar...' : ''">
                            <font-awesome-icon v-if="isSubmitting" icon="spinner" spin />
                            Spara
                        </b-button>
                    </b-form>
                </b-tab>
                <b-tab title="Behörighet"
                       lazy
                       :disabled="!isEditMode">
                    <Permissions :user-id="form.id" />
                </b-tab>
            </b-tabs>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters } from 'vuex';
    import { get, post } from '@/helpers/api';
    import Permissions from '@/components/user/Permissions';

    export default {
        name: 'SaveUser',
        components: {
            Permissions
        },
        props: {
            id: {
                type: String
            }
        },
        data() {
            return {
                activeTab: 0,
                isSubmitting: false,
                successMessage: '',
                validationErrors: null,
                form: {
                    id: null,
                    organizationId: null,
                    userName: '',
                    firstName: '',
                    lastName: '',
                    email: '',
                    password: '',
                    confirmPassword: '',
                    roles: []
                },
                allOrganizations: [],
                allRoles: []
            };
        },
        computed: {
            ...mapGetters('user', ['isInRole']),
            ...mapState({
                user: (state) => state.user.user,
                selectedCustomer: (state) => state.user.selectedCustomer,
            }),
            isEditMode() {
                return this.form.id;
            },
            isSuperAdmin() {
                return this.isInRole('SuperAdmin');
            },
        },
        async mounted() {
            // Get user if necessary.
            this.form.id = this.id !== '0' ? this.id : null;
            if (this.isEditMode) {
                await get('User',
                    this.selectedCustomer
                        ? `GetForCustomer?id=${this.form.id}&customerId=${this.selectedCustomer.id}`
                        : `Get?id=${this.form.id}`)
                    .then((x) => {
                        this.form = x.data;
                    })
                    .catch((x) => {
                        // Validate?
                    });
            }

            // Get roles.
            await get('Role', 'GetAll').then(x => this.allRoles = x.data);

            // Get organizations.
            if (this.isSuperAdmin && !this.isEditMode) {
                await get('Organization', 'GetAllActive').then(x => this.allOrganizations = x.data);
                this.form.organizationId = this.user.organizationId;
            }
        },
        methods: {
            async onSubmit() {
                // Resets.
                this.successMessage = '';
                this.validationErrors = null;
                this.isSubmitting = true;

                let apiAddress = (this.isEditMode
                    ? 'Edit'
                    : 'Create')

                apiAddress += this.selectedCustomer === null ? '' : `?customerId=${this.selectedCustomer.id}`;

                // Post.
                await post(
                    'User',
                    apiAddress,
                    this.form
                )
                    .then((x) => {
                        this.form.id = x.data.entityId;
                        this.successMessage = `Sparade ${this.form.firstName} ${this.form.lastName}`;
                        this.validationErrors = x.data.errors;
                    })
                    .catch((x) => {
                        this.validationErrors = x.response.data.errors;
                    });
                this.isSubmitting = false;

                // On created.
                if (!this.validationErrors && this.form.id) this.onCreated();
            },
            onCreated() {
                this.hasSavedSuccessfullly = true;
            },
            validationByKey(code) {
                return this.validationErrors ? this.validationErrors[code] || [] : [];
            },
            resetForm() {
                this.form = {
                    id: null,
                    //organizationId: null,
                    userName: '',
                    firstName: '',
                    lastName: '',
                    email: '',
                    password: '',
                    confirmPassword: ''
                };
                this.validationErrors = null;
            }
        }
    };
</script>

<style scoped>
    .form-group {
        margin-bottom: 0.5rem;
    }
</style>
